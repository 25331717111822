<template>
  <main>
    <h2>Testnet chain connector</h2>
    <p>Select the chain you want to connect to:</p>
    <button @click="connectToPulsar2">pulsar-2</button>
  </main>
</template>

<script>
import { getKeplr } from "./keplr";

export default {
  methods: {
    async connectToPulsar2() {
      const keplr = await getKeplr();
      keplr.experimentalSuggestChain({
        chainId: "pulsar-2",
        chainName: "pulsar-2",
        rpc: "http://testnet.securesecrets.org:26657/",
        rest: "http://testnet.securesecrets.org:1317/",
        bip44: {
          coinType: 529,
        },
        coinType: 529,
        stakeCurrency: {
          coinDenom: "SCRT",
          coinMinimalDenom: "uscrt",
          coinDecimals: 6,
        },
        bech32Config: {
          bech32PrefixAccAddr: "secret",
          bech32PrefixAccPub: "secretpub",
          bech32PrefixValAddr: "secretvaloper",
          bech32PrefixValPub: "secretvaloperpub",
          bech32PrefixConsAddr: "secretvalcons",
          bech32PrefixConsPub: "secretvalconspub",
        },
        currencies: [
          {
            coinDenom: "SCRT",
            coinMinimalDenom: "uscrt",
            coinDecimals: 6,
          },
        ],
        feeCurrencies: [
          {
            coinDenom: "SCRT",
            coinMinimalDenom: "uscrt",
            coinDecimals: 6,
          },
        ],
        gasPriceStep: {
          low: 0.1,
          average: 0.25,
          high: 0.4,
        },
        features: ["secretwasm"],
      });
    },
  },
};
</script>

<style>
:root {
  font-family: "Open Sans", sans-serif;
}

main {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  background-color: #efefef;
  padding: 12px;
  border-radius: 6px;
}
</style>
